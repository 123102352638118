<template>
  <PreviewText
    ref="inputRef"
    :value="value"
    :description="description"
    :preview="preview"
    :disabled="disabled"
    v-bind="$attrs"
    :regex="/^\d+(\.\d+)?/"
    @change="onChange"
  />
</template>

<script lang="ts" setup>
const props = defineProps<{
  value: string | undefined
  description?: string
  preview?: boolean
  fieldId?: string
  disabled?: boolean
}>()

const emit = defineEmits<{
  (e: 'change', value: string, fieldId?: string): void
  (e: 'set-callback', callback: { open: () => void }): void
}>()

const inputRef = ref<{
  focus: () => void
  blur: () => void
  changeValue: (value: string) => void
}>()

const onChange = (value: string) => {
  let formattedValue = value
  if (!/^\d+(\.\d+)?$/.test(value)) {
    const match = value?.match(/^\d+(\.\d+)?/)
    formattedValue = match ? match[0] : ''
  }

  inputRef.value?.blur()
  if (formattedValue != props.value) {
    return emit('change', formattedValue, props.fieldId)
  }

  inputRef.value?.changeValue(formattedValue)
}

defineExpose({ focus: () => inputRef.value?.focus() })

onMounted(() => {
  emit('set-callback', {
    open: () => inputRef.value?.focus(),
  })
})
</script>
